<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-6">
                    <ValidationProvider name="certificate_id" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('certificate')">
                            <certificate-center-certificate-selectbox v-model="form.certificate_id" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="certificate_person_id" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('certificate_persons')">
                            <certificate-person-selectbox v-model="form.certificate_person_id" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="price" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('price')">
                            <b-form-input
                                v-model="form.price"
                                type="number"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="gross_price" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('gross_price_certificate')">
                            <b-form-input
                                v-model="form.gross_price"
                                type="number"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="discount_amount" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('discount_amount')">
                            <b-form-input
                                v-model="form.discount_amount"
                                type="number"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="cash_discount_rate" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('cash_discount_rate_certificate')">
                            <b-form-input
                                v-model="form.cash_discount_rate"
                                type="number"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="ebys_id" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('ebys_id')">
                            <b-form-input
                                v-model="form.ebys_id"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="ebys_status" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('ebys_status')">
                            <status-selectbox
                                v-model="form.ebys_status"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="egovernment_code" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('egovernment_code')">
                            <b-form-input
                                v-model="form.egovernment_code"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="status" rules="" v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="form.status"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="note" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('note_for_document')">
                            <b-form-textarea
                                v-model="form.note"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    import CertificatePersonSelectbox from '@/components/interactive-fields/CertificatePersonSelectbox'
    import CertificateCenterCertificateSelectbox from '@/components/interactive-fields/CertificateCenterCertificateSelectbox'
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import CertificateApplicationService from "@/services/CertificateApplicationService";

    export default {
        components: {
            CertificatePersonSelectbox, CertificateCenterCertificateSelectbox, StatusSelectbox, ValidationProvider, ValidationObserver,
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    certificate_id: null,
                    certificate_person_id: null, 
                    gross_price: null,
                    discount_amount: null,
                    price: null,
                    cash_discount_rate: null,
                    status: null,
                    egovernment_code: null,
                    ebys_id: null,
                    ebys_status: null,
                    note: null
                }
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                CertificateApplicationService.get(id)
                               .then((response) => {
                                   this.form = response.data.data;
                                   this.formLoading = false;
                               })
                               .catch((error) => {
                                   if (error.data.message) {
                                       this.$toast.error(this.$t("api." + error.data.message));
                                   }
                               });
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    CertificateApplicationService.update(this.formId, this.form)
                                   .then((response) => {
                                       this.formLoading=false;
                                       this.$toast.success(this.$t("api." + response.data.message));
                                       this.$emit("updateFormSuccess")
                                   })
                                   .catch((error) => {
                                       this.showErrors(error, this.$refs.formModalValidate)
                                   })
                }
            }
        }
    }
</script>
