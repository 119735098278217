<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t('certificate')">
                    <b-input-group >
                        <div class="label-as-input">{{ form.certificate ? form.certificate.name : '-' }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('certificate_persons')">
                    <b-input-group>
                        <div class="label-as-input">{{ form.person ? form.person.name + ' ' + form.person.surname : '-' }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('price')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.price) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('gross_price_certificate')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.gross_price) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('discount_amount')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.discount_amount) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('cash_discount_rate_certificate')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.cash_discount_rate) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('ebys_id')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.ebys_id) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('ebys_status')">
                    <b-input-group>
                        <div class="label-as-input">{{ form.ebys_status === 'a' ? $t('active') : $t('passive') }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('egovernment_code')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.egovernment_code) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('status')">
                    <b-input-group>
                        <div class="label-as-input">{{ form.status === 'a' ? $t('active') : $t('passive') }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('note_for_document')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.note) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import CertificateApplicationService from '@/services/CertificateApplicationService';

    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                form: {
                    certificate: {
                        name: null
                    },
                    person: { 
                        name: '',
                        surname: ''
                    }, 
                    gross_price: null,
                    discount_amount: null,
                    price: null,
                    cash_discount_rate: null,
                    status: null,
                    egovernment_code: null,
                    ebys_id: null,
                    ebys_status: null,
                    note: null
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                CertificateApplicationService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.formLoading = false;
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                });
            }
        }
    }
</script>
