import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/certificate/applications', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/certificate/applications/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;

    return Api.post('/certificate/applications', data);
}

const downloadCertificateApplication = async (certificateApplicationId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    
    return Api.get('/certificate/applications/create-certificate/' + certificateApplicationId, {responseType: 'arraybuffer'});
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/certificate/applications/' + id, formData);
}

const deleteCertificateApplication = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/certificate/applications/' + id);
}

export default {
    getAll,
    get,
    update,
    store,
    downloadCertificateApplication,
    deleteCertificateApplication
}
